import React from "react";
import { packsList, questionariosList } from "../utils/questionarios";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";

const Questionarios = () => {
  const { questionarioPack } = useParams();

  const BATabs = () => {
    return (
      <Tabs
        defaultActiveKey="pack-completo"
        id="questionarios-tabs"
        className="questionarios-tabs">
        {packsList
          .filter((p) => p.id === questionarioPack)
          .map((p, k) => (
            <Tab eventKey={p.id} title={p.nome} key={k}>
              <div className="questionarios-list-buttons">
                {questionariosList
                  .filter((q) => q.packs.includes(p))
                  .map((q, qk) => (
                    <Link
                      to={q.slug}
                      className="ba-button secondary-button"
                      key={qk}>
                      {q.name}
                    </Link>
                  ))}
              </div>
            </Tab>
          ))}
      </Tabs>
    );
  };
  return (
    <div className="container">
      <p className="title">
        {
          packsList
            .filter((p) => p.id === questionarioPack)
            .map((p) => p.nome)[0]
        }
      </p>
      <div className="questionarios-list">
        {packsList
          .filter((p) => p.id === questionarioPack)
          .map((p, k) => (
            <div className="questionarios-list-buttons" key={k}>
              {questionariosList
                .filter((q) => q.packs.includes(p))
                .map((q, qk) => (
                  <Link
                    to={"/" + q.slug}
                    className="ba-button secondary-button"
                    key={qk}>
                    {q.name}
                  </Link>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Questionarios;
