import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getQuestionario } from "../utils/questionarios";
import { Model, settings } from "survey-core";
import { Survey } from "survey-react-ui";
import { Serializer } from "survey-core";
import { ProgressBar } from "react-bootstrap";
import { themeJson } from "../styles/theme";
import axios from "axios";
import { Converter } from "showdown";
import { makeid } from "../utils/utils";

//Add a custom 'popupdescription' property to questions of all types and to pages
Serializer.addProperty("question", "popupdescription:text");
Serializer.addProperty("page", "popupdescription:text");

function showDescription(element) {
  document.getElementById("questionDescriptionText").innerHTML =
    element.popupdescription;
  var popupEl = document.getElementById("questionDescriptionPopup");
  document.getElementById("questionDescriptionPopupClose").onclick = () => {
    popupEl.close();
  };
  popupEl.showModal();
}

const Questionario = () => {
  const { questionarioName } = useParams();
  const [pageNo, setPageNo] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Vai buscar as informações do questionário dependendo do slug
  const questionario = getQuestionario(questionarioName);

  // SaveLocalStorage
  const storageItemKey = questionarioName;

  function saveSurveyData(survey) {
    const data = survey.data;
    data.pageNo = 0; //survey.currentPageNo;
    const dataKeysWithoutMedia = Object.keys(survey.data)
      .filter((o) =>
        Array.isArray(survey.data[o])
          ? survey.data[o][0].content
            ? survey.data[o][0].content.includes("base64")
            : false
          : false
      )
      .map((o) => delete data[o]);

    window.localStorage.setItem(storageItemKey, JSON.stringify(data));
  }

  // Função chamada após finalizar o questionário
  const alertResults = useCallback((sender) => {
    sendJsonToServer();
  }, []);

  const getFileName = () => {
    const newDate = new Date();
    const date_raw = newDate.getDate();
    const month_raw = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const dateName = date_raw + "_" + month_raw + "_" + year;
    const clientName = survey.getQuestionByName("question_nome").value;
    const filenameClientName = clientName.replace(/\s/g, "");

    return (
      questionario.slug +
      "_" +
      dateName +
      "_" +
      filenameClientName +
      "_" +
      makeid(5)
    );
  };

  const sendEmail = ({ filename, uploadMessage, err }) => {
    const nomeCliente = survey.getQuestionByName("question_nome").value;

    const formData = {
      nome: nomeCliente,
      assunto: survey.title + " - " + nomeCliente,
      mensagem:
        "Nome do ficheiro: " +
        filename +
        " \n\nMensagem de upload: " +
        uploadMessage,
    };

    const params = new FormData();
    for (const field in formData) {
      params.append(field, formData[field]);
    }

    axios
      .post(
        "https://testequestionarios.barbaraalmeida.pt/php/sendMail.php",
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (!response.data.error && !err) {
          setShowMessage(true);
          setMessage(survey.completedHtml);

          // Empty the local storage after the survey is completed
          window.localStorage.setItem(storageItemKey, "");
        } else {
          setError(true);
          setShowErrorMessage(true);
          setErrorMessage(
            "Ocorreu um erro, tente novamente mais tarde. (f1l30r3m41l) <br>Pedimos desculpa pelo sucedido. <br>Para que não tenha que repetir o preenchimento, envie um email com o ficheiro que recebeu para evolui.onlinecoaching@gmail.com."
          );
          downloadFile({ filename: filename });
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setShowErrorMessage(true);
        setErrorMessage(
          "Ocorreu um erro, tente novamente mais tarde. (ax1053m41l) <br>Pedimos desculpa pelo sucedido. <br>Para que não tenha que repetir o preenchimento, envie um email com o ficheiro que recebeu para evolui.onlinecoaching@gmail.com."
        );
        setLoading(false);
        downloadFile({ filename: filename });
      });
  };

  // Envia o json para o Hostinger
  const sendJsonToServer = async () => {
    setLoading(true);
    const filename = getFileName() + ".json";
    const jsonData = {
      filename: filename,
      survey: survey.data,
    };

    try {
      const response = await axios.post(
        "https://testequestionarios.barbaraalmeida.pt/php/uploadFile.php",
        jsonData
      );
      console.log(response.data); // You can handle the server's response here
      sendEmail({
        filename: filename,
        uploadMessage: response.data,
        err: response.data.includes("Error:"),
      });
    } catch (error) {
      console.error("Error sending JSON data:", error);
      console.log(error.toJSON());
      sendEmail({
        filename: filename,
        uploadMessage:
          "Error sending JSON data: " +
          error.code +
          " \n" +
          error.message +
          " | " +
          error.config.url +
          " * " +
          error.config.timeout +
          " * " +
          error.config.method +
          "\nDespcription: " +
          error.toJSON().description,
        err: true,
      });
      setError(true);
      setShowErrorMessage(true);
      setErrorMessage(
        "Ocorreu um erro ao enviar o questionário, tente novamente mais tarde. (ax105f1l3) <br>Pedimos desculpa pelo sucedido. <br>Para que não tenha que repetir o preenchimento, envie um email com o ficheiro que recebeu para evolui.onlinecoaching@gmail.com."
      );
      setLoading(false);
    }
  };

  const downloadFile = ({ filename }) => {
    // create file in browser
    const json = JSON.stringify(survey.data, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  // Cria o questionário através do JSON
  const survey = new Model(questionario?.questoesJSON);
  survey.applyTheme(themeJson);
  settings.minWidth = "200px";
  survey.showCompletedPage = false;
  //survey.showNavigationButtons = false;

  // Quando clicar no Complete chama o callback "alertResults"
  survey.onComplete.add(alertResults);

  // Quando a página muda, o state da PageNumber muda também por causa da progressBar
  survey.onCurrentPageChanged.add((_, options) => {
    setPageNo(options.newCurrentPage.visibleIndex);
    survey.clearInvisibleValues = "onHidden";
  });

  // Add an Info icon on quastions that have "popupdescription"
  survey.onGetQuestionTitleActions.add((_, opt) => {
    if (opt.question.popupdescription) {
      const icon =
        opt.question.acceptedTypes === "image/*"
          ? "📸"
          : opt.question.acceptedTypes === "video/*" ||
            opt.question.popupdescription.includes("<video") ||
            opt.question.popupdescription.includes("videoFromAssets")
          ? "🎥"
          : "ℹ️";
      opt.titleActions = [
        {
          title: icon,
          innerCss: "btn-more-info fa-regular fa-2x",
          action: () => {
            if (opt.question.popupdescription.includes("videoFromAssets")) {
              const video = {
                videoFromAssets_Peito: "iUhV8fFSBDk",
                videoFromAssets_BraçosRelaxados: "xizMtrv7YiE",
                videoFromAssets_BraçosContraídos: "FsD_pawyat8",
                videoFromAssets_Cintura: "CdYseGFXYHk",
                videoFromAssets_Umbigo: "7bzbVF39QWA",
                videoFromAssets_3dedosabaixodoumbigo: "w9h5x8MSKmA",
                videoFromAssets_Glúteos: "mUFJRGavSZ0",
                videoFromAssets_CoxaSuperior: "ECYQmUP411U",
                videoFromAssets_CoxaInferior: "H_anXZ-mvNo",
                videoFromAssets_Gémeos: "QOsB68aGqDI",
                videoFromAssets_Vídeo3Poses: "Ij7pUxmLtLg",
              };

              opt.question.popupdescription =
                "<iframe width='320' height='569' src='https://www.youtube.com/embed/" +
                video[opt.question.popupdescription] +
                "' frameborder='0'></iframe>";
            }
            showDescription(opt.question);
          },
        },
      ];
    }
  });

  // Add an Info icon on pages that have "popupdescription"
  survey.onGetPageTitleActions.add((_, opt) => {
    if (opt.page.popupdescription) {
      opt.titleActions = [
        {
          title: "ⓘ",
          innerCss: "btn-more-info",
          action: () => {
            showDescription(opt.page);
          },
        },
      ];
    }
  });

  // Create showdown markdown converter
  var converter = new Converter();
  survey.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    var str = converter.makeHtml(options.text);
    //remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    //set html
    options.html = str;
  });

  // SaveLocalStorage
  survey.onValueChanged.add(saveSurveyData);
  survey.onCurrentPageChanged.add(saveSurveyData);

  // SaveLocalStorage: Restore survey results
  useEffect(() => {
    const prevData = window.localStorage.getItem(storageItemKey) || null;
    if (prevData) {
      const data = JSON.parse(prevData);
      survey.data = data;
      if (data.pageNo) {
        survey.currentPageNo = data.pageNo;
      }
    }
  }, []);

  const progressBar = () => {
    const progressPercentage =
      ((pageNo + 1) * 100) / survey.visiblePages.length;

    return (
      <div className="px-3">
        <ProgressBar now={progressPercentage} className="ba-progress" />
      </div>
    );
  };

  const SurveyComponent = React.useCallback(
    () => <Survey model={survey} currentPageNo={pageNo} />,
    []
  );

  return (
    <div className="container">
      <div className="px-md-3">{progressBar()}</div>
      <SurveyComponent />

      {loading && (
        <div className="d-flex justify-content-center">
          <span className="loader"></span>
        </div>
      )}

      {showMessage && (
        <div
          className="d-flex justify-content-center text-center mt-4"
          dangerouslySetInnerHTML={{ __html: message }}></div>
      )}

      {showErrorMessage && (
        <div
          className="d-flex justify-content-center text-center mt-4 text-danger"
          dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
      )}
    </div>
  );
};

export default Questionario;
