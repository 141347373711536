import React from "react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";

const CriarQuestionario = () => {
  const creatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
    showJSONEditorTab: true,
  };

  const creator = new SurveyCreator(creatorOptions);

  return <SurveyCreatorComponent creator={creator} />;
};

export default CriarQuestionario;
