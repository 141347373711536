export const themeJson = {
  cssVariables: {
    "--sjs-general-backcolor": "rgba(246, 246, 246, 1)",
    "--sjs-general-backcolor-dark": "rgba(235, 235, 235, 1)",
    "--sjs-general-backcolor-dim": "#F7F7F7",
    "--sjs-general-backcolor-dim-light": "rgba(255, 255, 255, 1)",
    "--sjs-general-backcolor-dim-dark": "rgba(235, 235, 235, 1)",
    "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
    "--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.9)",
    "--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
    "--sjs-general-dim-forecolor-light": "rgba(0, 0, 0, 0.9)",
    "--sjs-primary-backcolor": "rgba(30, 76, 238, 1)",
    "--sjs-primary-backcolor-light": "rgba(30, 76, 238, 0.1)",
    "--sjs-primary-backcolor-dark": "rgba(27, 69, 216, 1)",
    "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-base-unit": "8px",
    "--sjs-corner-radius": "14px",
    "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
    "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
    "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
    "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-shadow-small": "0px 0px 0px 1px rgba(0, 0, 0, 0.15)",
    "--sjs-shadow-medium":
      "0px 2px 6px 0px rgba(0, 0, 0, 0.1),0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
    "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
    "--sjs-shadow-inner": "0px 0px 0px 1px rgba(0, 0, 0, 0.12)",
    "--sjs-border-light": "rgba(0, 0, 0, 0.12)",
    "--sjs-border-default": "rgba(0, 0, 0, 0.12)",
    "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
    "--sjs-special-red": "rgba(229, 10, 62, 1)",
    "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
    "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-green": "rgba(25, 179, 148, 1)",
    "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
    "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-blue": "rgba(67, 127, 217, 1)",
    "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
    "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
    "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
    "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-font-surveytitle-family": "Montserrat",
    "--sjs-font-surveytitle-weight": "600",
    "--sjs-font-surveytitle-color": "rgba(0, 0, 0, 1)",
    "--sjs-font-surveytitle-size": "24px",
    "--sjs-font-pagetitle-family": "Montserrat",
    "--sjs-font-pagetitle-weight": "600",
    "--sjs-font-pagetitle-color": "rgba(0, 0, 0, 1)",
    "--sjs-font-pagetitle-size": "24px",
    "--sjs-font-questiontitle-family": "Montserrat",
    "--sjs-font-questiontitle-weight": "600",
    "--sjs-font-questiontitle-color": "rgba(0, 0, 0, 1)",
    "--sjs-font-questiontitle-size": "16px",
    "--sjs-editor-background": "rgba(255, 255, 255, 1)",
    "--sjs-editorpanel-backcolor": "rgba(255, 255, 255, 1)",
    "--sjs-editorpanel-hovercolor": "rgba(247, 247, 247, 1)",
    "--sjs-editorpanel-cornerRadius": "14px",
    "--sjs-font-editorfont-family": "Montserrat",
    "--sjs-font-editorfont-weight": "400",
    "--sjs-font-editorfont-color": "rgba(0, 0, 0, 1)",
    "--sjs-font-editorfont-placeholdercolor": "rgba(0, 0, 0, 0.45)",
    "--sjs-font-editorfont-size": "16px",
  },
  themeName: "flat",
  colorPalette: "light",
  isPanelless: true,
};
