import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getQuestionario } from "../utils/questionarios";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { Serializer } from "survey-core";
import { ProgressBar } from "react-bootstrap";
import { themeJson } from "../styles/theme";
import axios from "axios";

//Add a custom 'popupdescription' property to questions of all types and to pages
Serializer.addProperty("question", "popupdescription:text");
Serializer.addProperty("page", "popupdescription:text");

function showDescription(element) {
  document.getElementById("questionDescriptionText").innerHTML =
    element.popupdescription;
  var popupEl = document.getElementById("questionDescriptionPopup");
  document.getElementById("questionDescriptionPopupClose").onclick = () => {
    popupEl.close();
  };
  popupEl.showModal();
}

const QuestionarioRead = () => {
  const [files, setFiles] = useState("");

  const { questionarioName } = useParams();
  const [pageNo, setPageNo] = useState(0);

  // Vai buscar as informações do questionário dependendo do slug
  const questionario = getQuestionario(questionarioName);

  // Callback para pegar nos resultados do questionário
  const alertResults = useCallback((sender) => {
    const finalResults = sender.getPlainData().map((q) => ({
      title: q.title,
      displayValue: q.displayValue,
    }));
  }, []);

  // Cria o questionário através do JSON
  const [survey] = useState(new Model(questionario?.questoesJSON));
  survey.applyTheme(themeJson);
  //survey.showNavigationButtons = false;

  const handleChange = (e) => {
    const fileReader = new FileReader();
    if (e.target.files[0]) {
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        setFiles(e.target.result);
        const res = JSON.parse(e.target.result);
        if (res) survey.data = res;
        survey.showPreview();
        survey.clearInvisibleValues = true;
      };
    }
  };

  // Quando clicar no Complete chama o callback "alertResults"
  survey.onComplete.add(alertResults);

  // Quando a página muda, o state da PageNumber muda também por causa da progressBar
  survey.onCurrentPageChanged.add((_, options) => {
    setPageNo(options.newCurrentPage.visibleIndex);
  });

  // Add an Info icon on quastions that have "popupdescription"
  survey.onGetQuestionTitleActions.add((_, opt) => {
    if (opt.question.popupdescription) {
      opt.titleActions = [
        {
          title: "ⓘ",
          innerCss: "btn-more-info",
          action: () => {
            showDescription(opt.question);
          },
        },
      ];
    }
  });

  // Add an Info icon on pages that have "popupdescription"
  survey.onGetPageTitleActions.add((_, opt) => {
    if (opt.page.popupdescription) {
      opt.titleActions = [
        {
          title: "ⓘ",
          innerCss: "btn-more-info",
          action: () => {
            showDescription(opt.page);
          },
        },
      ];
    }
  });

  const progressBar = () => {
    const progressPercentage =
      ((pageNo + 1) * 100) / survey.visiblePages.length;

    return (
      <div className="px-3">
        <ProgressBar now={progressPercentage} className="ba-progress" />
      </div>
    );
  };

  const SurveyComponent = React.useCallback(
    () => <Survey model={survey} currentPageNo={pageNo} />,
    []
  );

  return (
    <div className="container">
      <div className="d-flex justify-content-center pb-4">
        <input
          className="ba-fileinput"
          type="file"
          accept=".json, .doc"
          onChange={handleChange}
        />
      </div>

      {files.length > 0 && (
        <div className="questionario-ler">
          <div className="px-3">{progressBar()}</div>
          <SurveyComponent />
        </div>
      )}
    </div>
  );
};

export default QuestionarioRead;
