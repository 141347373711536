import questionarioInicialPackCompleto from "./questionarios/questionario-inicial-pack-completo.json";
import questionarioInicialPackTreino from "./questionarios/questionario-inicial-pack-treino.json";
import questionarioAvulsoAlimentar from "./questionarios/questionario-avulso-alimentar.json";
import questionarioAvulsoTreino from "./questionarios/questionario-avulso-treino.json";
import questionarioRevisoesPackCompleto from "./questionarios/questionario-revisoes-pack-completo.json";
import questionarioRevisoesPackTreino from "./questionarios/questionario-revisoes-pack-treino.json";
import questionarioFinal from "./questionarios/questionario-final.json";

export const packsList = [
  {
    id: "pack-completo",
    nome: "Pack Completo",
  },
  {
    id: "pack-treino",
    nome: "Pack Treino",
  },
  {
    id: "pack-avulso",
    nome: "Pack Avulso",
  },
];

export const questionariosList = [
  {
    name: "Questionário inicial",
    slug: "questionario-inicial-pack-completo",
    questoesJSON: questionarioInicialPackCompleto,
    packs: [packsList[0]],
  },
  {
    name: "Questionário de revisões",
    slug: "questionario-revisoes-pack-completo",
    questoesJSON: questionarioRevisoesPackCompleto,
    packs: [packsList[0]],
  },
  {
    name: "Questionário inicial",
    slug: "questionario-inicial-pack-treino",
    questoesJSON: questionarioInicialPackTreino,
    packs: [packsList[1]],
  },
  {
    name: "Questionário de revisões",
    slug: "questionario-revisoes-pack-treino",
    questoesJSON: questionarioRevisoesPackTreino,
    packs: [packsList[1]],
  },
  {
    name: "Guia Alimentar",
    slug: "questionario-avulso-alimentar",
    questoesJSON: questionarioAvulsoAlimentar,
    packs: [packsList[2]],
  },
  {
    name: "Plano de Treino",
    slug: "questionario-avulso-treino",
    questoesJSON: questionarioAvulsoTreino,
    packs: [packsList[2]],
  },
  {
    name: "Questionário final",
    slug: "questionario-final",
    questoesJSON: questionarioFinal,
    packs: [packsList[0], packsList[1]],
  },
];

export const getQuestionario = (questionarioName) => {
  const questionario = questionariosList.filter(
    (o) => o.slug === questionarioName
  )?.[0];
  return questionario;
};
