import "./styles/styles.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <>
      <Header />
      <div className="">
        <div className="body">
          <Outlet />
        </div>
      </div>
      <Footer />

      <dialog id="questionDescriptionPopup" className="dialogBox">
        <div className="dialogBox-body">
          <div id="questionDescriptionText"></div>
        </div>
        <div id="questionDescriptionPopupClose" className="dialogBox-footer">
          <button type="button" className="dialogBox-close ba-button mt-4">
            Fechar
          </button>
        </div>
      </dialog>
    </>
  );
}

export default App;
