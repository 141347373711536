import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="py-5">
      <div className="quest-title text-center">
        <Link to="/" className="evolui-logo">
          evolui.
        </Link>
        <h4 className="title">Online Coaching</h4>
      </div>
    </div>
  );
};

export default Header;
